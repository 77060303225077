import StoreTestComponent from '@/components/product/StoreTestComponent1.vue'

export const practiceRouter = [
    {
        path: '/practice/storetest',
        name: 'StoreTestComponent',
        component: StoreTestComponent
    },


]